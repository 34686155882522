import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { codevilleHomeheroImage, FileIcon, RightArrowWhite } from "../../../../assets";
import { CodeVilleButton, Loader, Modal, Toast } from "../../../../components";
import { CODEVILLE_APP_LINKS } from "../../../../config";
import styles from "./styles.module.css";
import { SendEmail } from "../../../../utils/codeville-email";

const CodeVilleHomeHeroSection = () => {
  const navigate = useNavigate();

  const [formInfo, setFormInfo] = useState({
    email: "",
    loading: false,
  });

  const [toastData, setToastData] = useState({
    message: "",
    display: false,
    status: null
  })

  const brochureModalRef = useRef();

  function openBrochureModal() {
    if (brochureModalRef.current) {
      brochureModalRef.current.openModal(true);
    }
  }

  // Apply button Click hanler
  const applyButtonClick = () => {
    let currentApplication = CODEVILLE_APP_LINKS.kidscodeCamp;

    // Object.values(CODEVILLE_APP_LINKS).forEach((val) => {
    //   if (val !== "") {
    //     currentApplication = val;
    //   }
    // });

    if (currentApplication) {
      return window.open(currentApplication, "_blank");
    } else {
      // return navigate("/codeville/closed-app/");
      return navigate("https://forms.gle/JJJcMxGvdVUo8fBeA");
    }
  };

  const sendBrochure = async () => {
    try {
      // Set form loader
      setFormInfo((prevState) => ({
        ...prevState,
        loading: true,
      }));
      if (formInfo.email.trim().length < 1) {
        setToastData({
          message: "Email is required",
          status: "warning",
          display: true,
        })
      } else {
        // Send email
        await SendEmail(formInfo.email, "Codeville Brochure Request");
        // Show success toast
        setToastData({
          display: true,
          status: "success",
          message: "Email sent successfully",
        })
        // Hide modal
        brochureModalRef.current.openModal(false);
      }
      // Reset form
      setFormInfo({
        email: "",
        loading: false,
      });
    } catch (error) {
      // Display toast
      setToastData({
        message: "Error sending email",
        status: "error",
        display: true,
      })
      // Reset form
      setFormInfo({
        email: "",
        loading: false,
      });
    }
  };

  return (
    <>
      {(toastData.display) && <Toast
        message={toastData.message}
        toastState={toastData.status}
        setDisplayToast={(display)=>setToastData((prevState)=>({
          ...prevState,
          display
        }))}
        key={"codeville-brochure-toast"}
      />}
      <section className={`${styles.heroContainer} codevilleSectionWrapper`}>
        <aside className={styles.heroContent}>
          <h1>{`Securing Tomorrow's Success by Harnessing Today's Talent.`}</h1>
          <p>
            With Codeville, your child can be positioned well ahead of the curve, setting him/her several steps ahead in
            the world&apos;s race for technology breakthrough.
          </p>

          <div className={styles.actionContainer}>
            <CodeVilleButton
              onClick={applyButtonClick}
              label="Apply"
              icon={<RightArrowWhite />}
              style={{ width: "118px" }}
            />

            {/* Brochure button */}
            <CodeVilleButton
              buttonType="secondary"
              label="Download Brochure"
              icon={<FileIcon className={styles.nofillIcon} />}
              onClick={openBrochureModal}
            />
          </div>
        </aside>

        <aside className={styles.heroImageContainer}>
          <img src={codevilleHomeheroImage} alt="hero" />
        </aside>
      </section>

      {/* Modals */}
      <Modal ref={brochureModalRef} initialLoadState={false} key={"brochure-modal"}>
        <div className={styles.BrochureModal}>
          <div className={styles.BrochureModalWrapper}>
            <h2>Download Brochure</h2>
            <p>Kindly submit your email address to receive our program brochure</p>
            <div className={styles.inputGroup}>
              <input
                type="email"
                placeholder="Email address"
                value={formInfo?.email}
                onChange={(e) => {
                  setFormInfo((prevState) => ({
                    ...prevState,
                    email: e.target.value,
                  }));
                }}
                disabled={formInfo.loading}
              />
            </div>
            <CodeVilleButton label={`Submit Response`} buttonType="primary" onClick={()=>sendBrochure()} disabled={formInfo.loading} />
            {(formInfo.loading) && <Loader key={"email-sending"}/>}
          </div>
        </div>
      </Modal>
    </>
  );
};

export { CodeVilleHomeHeroSection };
