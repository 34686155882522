import React, { memo, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { CaretDownIcon, codevilleLogo, HamburgerMenu, NavBarCloseIcon } from "../../../assets";
import { CODEVILLE_APP_LINKS } from "../../../config";
import { CodeVilleButton } from "../button";
import styles from "./styles.module.css";

const CodeVilleNavbar = memo(() => {
  let navigate = useNavigate();

  // Code Ville Nav Items
  const NAV_LINK_LIST = useMemo(
    () => [
      // {
      //   title: "Home",
      //   link: "/codeville/",
      // },
      // {
      //   title: "Courses",
      //   link: "/codeville/courses",
      //   disabled: true,
      // },
      {
        title: "Programmes",
        link: "/codeville/programmes",
        dropdownItems: [
          { title: "Codeville Junior Academy", link: "/codeville/programmes/junior-academy" },
          { title: "Codeville Middle Class", link: "/codeville/programmes/middle-class" },
          { title: "Codeville Kids Code Camp", link: "/codeville/programmes/kids-code-camp" },
          // { title: "Codeville Virtual Camp", link: "/codeville/programmes/virtual-camp" },
        ],
      },
      {
        title: "Gallery",
        dropdownItems: [
          { title: "Junior Academy", link: "/codeville/gallery/junior-academy" },
          { title: "Kids Code Camp", link: "/codeville/gallery/kids-code-camp" },
        ],
      },
      {
        title: "About",
        dropdownItems: [
          {title: "About Codeville", link: "/codeville/about"},
          {title: "Testimonials", link: "/codeville/testimonials"},
        ]
      },
      { title: "Contact", link: "/codeville/contact" },
    ],
    [],
  );

  // Event listener for window resize
  const [navbarType, setNavbarType] = useState("desktop");

  // Effect to handle navbar type update
  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth > 1155) {
        setNavbarType("desktop");
      } else setNavbarType("mobile");
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  // Load Navbar handler
  const [showNavbar, setShowNavbar] = useState(false);
  const handleLoadNavbar = () => setShowNavbar((navbarState) => !navbarState);

  // Applications page navigation
  const handleApplicationsPageNav = () => {
    let currentApplication = "";

    setShowNavbar(false);

    Object.values(CODEVILLE_APP_LINKS).forEach((val) => {
      if (val !== "") {
        currentApplication = val;
      }
    });

    if (currentApplication) {
      return window.open(currentApplication, "_blank");
    } else {
      // return navigate("/codeville/closed-app/");
      return navigate("https://forms.gle/JJJcMxGvdVUo8fBeA");
    }
  };

  return (
    <div className={styles.navContainer}>
      <nav className={styles.navWrapper}>
        <a href="/codeville/">
          <img onClick={() => navigate("/")} src={codevilleLogo} alt="codeville logo" />
        </a>

        {navbarType === "mobile" && showNavbar ? (
          <React.Fragment>
            <ul className={`${styles.linksContainer} ${styles[navbarType]}`}>
              {NAV_LINK_LIST.map((linkItem) => {
                return (
                  <NavLinkItem
                    {...linkItem}
                    key={linkItem.title}
                    navbarType={navbarType}
                    setShowNavbar={setShowNavbar}
                  />
                );
              })}
              <CodeVilleButton
                style={{ width: "243px", marginTop: "89px" }}
                label="Apply"
                buttonType="primary"
                onClick={handleApplicationsPageNav}
              />
            </ul>
          </React.Fragment>
        ) : navbarType === "mobile" ? (
          <HamburgerMenu onClick={handleLoadNavbar} />
        ) : null}

        {showNavbar && navbarType === "mobile" && <NavBarCloseIcon onClick={handleLoadNavbar} />}

        {navbarType === "desktop" && (
          <section className={styles.linksWrapper}>
            <ul className={`${styles.linksContainer}`}>
              {NAV_LINK_LIST.map((linkItem) => {
                return <NavLinkItem key={linkItem.title} {...linkItem} navbarType={navbarType} />;
              })}
            </ul>
            <CodeVilleButton label="Apply" buttonType="primary" onClick={handleApplicationsPageNav} />
          </section>
        )}
      </nav>
    </div>
  );
});

const NavLinkItem = ({
  title,
  link,
  dropdownItems = [],
  disabled = false,
  navbarType = "desktop",
  setShowNavbar = () => {},
}) => {
  // Dropdown List Check
  const hideDropdownList = dropdownItems.length < 1;
  const isActiveDropList = !hideDropdownList && location.pathname.includes(link);

  // State variables
  const [activeDropDownState, setActiveDropdownState] = useState(() => isActiveDropList || false);
  const dropdownLinkRef = useRef(null);
  const dropdownRef = useRef(null);

  // Effect to close dropdown when a click outside happens
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        dropdownLinkRef.current &&
        !dropdownLinkRef.current.contains(e?.target) &&
        !dropdownLinkRef.current.contains(e?.target)
      ) {
        setActiveDropdownState(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    handleClickOutside();

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dropdownRef, dropdownLinkRef]);

  return (
    <React.Fragment>
      <li
        ref={dropdownLinkRef}
        key={title}
        className={`${styles.linkItem} ${disabled ? styles.disabled : ""}  ${navbarType ? styles[navbarType] : ""}`}
        onClick={() => setActiveDropdownState((activeState) => !activeState)}
      >
        <div className={styles.linkText}>
          {disabled ? (
            <span className={styles.disabled}>{title}</span>
          ) : hideDropdownList ? (
            <NavLink
              className={({ isActive }) => (isActive ? styles.active : "")}
              onClick={() => setShowNavbar(false)}
              to={link}
            >
              {title}
            </NavLink>
          ) : isActiveDropList ? (
            <span className={`${styles.isActiveLinkWithDropdown} ${navbarType ? styles[navbarType] : ""}`}>
              {title}
            </span>
          ) : (
            title
          )}

          {!hideDropdownList && (
            <CaretDownIcon
              className={`
              ${styles.caretDown} 
              ${isActiveDropList ? styles.activeDropList : ""} 
              ${navbarType ? styles[navbarType] : ""}
            `}
            />
          )}
        </div>

        {activeDropDownState && !hideDropdownList && (
          <ul
            ref={dropdownRef}
            className={`
            ${styles.dropdownList}
            ${navbarType ? styles[navbarType] : ""}
          `}
          >
            {dropdownItems.map((dropItem) => {
              return (
                <li
                  key={dropItem.title}
                  className={`
                  ${styles.dropListItem}
                  ${navbarType ? styles[navbarType] : ""}
                `}
                  onClick={() => setShowNavbar(false)}
                >
                  <NavLink className={({ isActive }) => (isActive ? styles.active : "")} to={dropItem.link}>
                    {dropItem.title}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        )}
      </li>
    </React.Fragment>
  );
};

// Display name
CodeVilleNavbar.displayName = "Codeville Nav Bar";

export { CodeVilleNavbar };
